import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  managePageHrefLink,
  managePageHrefLinkMobile,
  tableLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// templarte img
import img1 from "./img/template1.png";
import img2 from "./img/template2.png";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";

//pages & includes
import Templatesidebar from "./Templatesidebar";

const Alltemplate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  // getting context value
  const { loading, setLoading } = useContext(SettingsContext);
  // loading
  // const [loading, setloading] = useState(true);
  // state starts here
  const [templateIndex, setTemplateIndex] = useState(2);

  // onchange function
  const handleOnChange = (e) => {
    setTemplateIndex(e);
  };

  // getTemplate value
  const getActivatedTemplate = (e) => {
    // setLoading(true);
    const url = BASE_URL + "/settings/get-template-value";
    return axios.get(url).then((res) => {
      setTemplateIndex(res.data);
      // setLoading(false);
    });
  };
  // useEffect
  useEffect(() => {
    getActivatedTemplate();
  }, []);

  // handle submit
  const handlesubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("value", templateIndex);
    const url = BASE_URL + "/settings/set-template-value";
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data.status == 200) {
          toast.success(`${_t(t("updated successfully"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          // re-render all component
          window.location.reload();
        } else {
          toast.error(`${_t(t("Try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Template"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              {/* <Templatesidebar /> */}
              {/* Navigation for Small Screen  */}
              <div className="d-lg-none">
                <div className="row">
                  <div className="col-12">
                    <div className="fk-sm-nav" data-simplebar>
                      {templateIndex == 1 ? (
                        <ul className="t-list fk-sm-nav__bar flex-row">
                          {/* Template two settings */}
                          {/* {managePageHrefLinkMobile(
                                                        "/dashboard/template/hero-section",
                                                        _t(t("Hero"))
                                                    )} */}

                          {managePageHrefLinkMobile(
                            "/dashboard/template/hero-one-slider",
                            _t(t("Hero slider"))
                          )}

                          {managePageHrefLinkMobile(
                            "/dashboard/template/promotions",
                            _t(t("Promotions"))
                          )}
                        </ul>
                      ) : (
                        <ul className="t-list fk-sm-nav__bar flex-row">
                          {/* Template two settings */}
                          {managePageHrefLinkMobile(
                            "/dashboard/template/edit-hero",
                            _t(t("Hero section"))
                          )}

                          {managePageHrefLink(
                            "/dashboard/template/edit-about",
                            _t(t("About section"))
                          )}

                          {managePageHrefLink(
                            "/dashboard/template/edit-feature",
                            _t(t("Feature section"))
                          )}

                          {managePageHrefLink(
                            "/dashboard/template/edit-gellary",
                            _t(t("Gallery section"))
                          )}

                          {managePageHrefLink(
                            "/dashboard/template/edit-delivery",
                            _t(t("Delivery section"))
                          )}

                          {managePageHrefLink(
                            "/dashboard/template/change-template",
                            _t(t("Choose template"))
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Navigation for Small Screen  End*/}

              {/* Navigation for large Screen  */}
              <div className="d-none d-lg-block">
                <div className="fk-scroll--pos-menu" data-simplebar>
                  <ul className="t-list fk-pos-nav">
                    {/* Foods */}
                    <li
                      className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                        window.location.pathname.includes("/template/")
                          ? "active"
                          : ""
                      }`}
                    >
                      <a
                        className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                        rel="noopener noreferrer"
                        href="#"
                      >
                        {templateIndex == 1
                          ? _t(t("Template one"))
                          : _t(t("Template two"))}
                      </a>
                      {templateIndex == 1 ? (
                        <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                          {/* {managePageHrefLink(
                            "/dashboard/template/hero-section",
                            _t(t("Hero"))
                          )} */}

                          {managePageHrefLink(
                            "/dashboard/template/hero-one-slider",
                            _t(t("Hero slider"))
                          )}

                          {managePageHrefLink(
                            "/dashboard/template/promotions",
                            _t(t("Promotions"))
                          )}
                          {managePageHrefLink(
                            "/dashboard/template/change-template",
                            _t(t("Choose template"))
                          )}
                        </ul>
                      ) : (
                        <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                          {managePageHrefLink(
                            "/dashboard/template/edit-hero",
                            _t(t("Hero section"))
                          )}

                          {managePageHrefLink(
                            "/dashboard/template/edit-about",
                            _t(t("About section"))
                          )}

                          {managePageHrefLink(
                            "/dashboard/template/edit-feature",
                            _t(t("Feature section"))
                          )}

                          {managePageHrefLink(
                            "/dashboard/template/edit-gellary",
                            _t(t("Gallery section"))
                          )}

                          {managePageHrefLink(
                            "/dashboard/template/edit-delivery",
                            _t(t("Delivery section"))
                          )}

                          {managePageHrefLink(
                            "/dashboard/template/change-template",
                            _t(t("Choose template"))
                          )}
                        </ul>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              {/* Navigation for large Screen End */}
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0 ">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide t-pt-50 pl-3"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    <form onSubmit={handlesubmit}>
                      <div className="row">
                        <div className="col-lg-12"></div>
                        <div className=" col-md-6 col-lg-4 mb-4">
                          <label className="template-show-card card shadow">
                            <input
                              type="radio"
                              name="templateOne"
                              checked={templateIndex == 1 ? "checked" : ""}
                              className="templateradio"
                              onChange={() => handleOnChange(1)}
                            />
                            <img className="preview-img" src={img1} alt="" />
                          </label>
                          <h5 className="template-title text-center">
                            {_t(t("Template one"))}
                          </h5>
                        </div>
                        <div className=" col-md-6 col-lg-4 mb-4">
                          <label className="template-show-card card shadow">
                            <input
                              type="radio"
                              name="templateOne"
                              checked={templateIndex == 2 ? "checked" : ""}
                              className="templateradio"
                              onChange={() => handleOnChange(2)}
                            />
                            <img className="preview-img" src={img2} alt="" />
                          </label>
                          <h5 className="template-title text-center">
                            {_t(t("Template two"))}
                          </h5>
                        </div>
                        <div className=" col-md-6 col-lg-4 mb-4">
                          <label className="template-show-card card shadow d-flex justify-content-center align-items-center px-5 text-center">
                            <h4>More are cooming soon</h4>
                          </label>
                        </div>
                      </div>
                      <button className="btn btn-danger text-capitalize">
                        activate now
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default Alltemplate;
